import * as React from "react"
import AppLayout from "../components/AppLayout";
import HomePage from "../components/HomePage";

const IndexPage = () => {
    return (
        <AppLayout>
            <HomePage/>
        </AppLayout>
    )
}

export default IndexPage
