import * as React from "react"
import {Row, Space} from "antd";
import {LoadingOutlined, PlusCircleFilled} from "@ant-design/icons";
import {Link} from "gatsby";
import {BigButton} from "./Elements";
import {YMaps, Map, Placemark, SearchControl} from 'react-yandex-maps';
import {gql, useQuery} from "@apollo/client";
import {useEffect, useState} from "react";


const HomePage = () => {

    const GET_COORDINATES = gql`
        query reports {
            reports {
                id
                coordinates
                title
                status
                reportFiles {
                    name
                }
            }
        }
    `;

    const {loading, error, data} = useQuery(GET_COORDINATES);
    const [reports, setReports] = useState([]);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [ymapsObj, setYmapsObj] = useState();
    const [myMap, setMyMap] = useState();
    const [mapState, setMapState] = useState({
        center: [47.21426361523557, 39.7219220944648],
        zoom: 13
    });

    useEffect(() => {
        if (!loading && !error && data !== undefined && data !== null) {
            setReports(data.reports);
        }
    }, [loading, error, data]);

    useEffect(() => {
        if (ymapsObj) {
            // Пока не знаю как по-другому дождаться myMap.geoObjects.getBounds()
            setTimeout(() => {
                if (myMap.geoObjects.getBounds()) { // Если не дождётся, то хотя бы не будет ошибки
                    let centerAndZoom = ymapsObj.util.bounds.getCenterAndZoom(
                        myMap.geoObjects.getBounds(),
                        myMap.container.getSize(),
                        myMap.options.get('projection')
                    );
                    setMapState(centerAndZoom);
                }
            }, 1000);
        }
    }, [ymapsObj]);

    const getPropertiesByStatus = (id, status, title) => {
        let result = {hintContent: null, balloonContent: null}
        if (status === 1) {
            result = {
                hintContent: 'В работе' + (title ? ' - ' + title : ''),
                balloonContentHeader: 'В работе' + (title ? ' - ' + title : ''),
                balloonContent: null
            }
        }
        if (status === 2) {
            result = {
                hintContent: 'Есть ответ' + (title ? ' - ' + title : ''),
                balloonContentHeader: 'Есть ответ' + (title ? ' - ' + title : ''),
                balloonContentBody: getArrayOfFiles(id)
            }
        }
        return result;
    }

    const getPlacemarkColorByStatus = (status) => {
        if (status === 1) {
            return "orange";
        }
        if (status === 2) {
            return "#287a2c";
        }
    }

    const getArrayOfFiles = (id) => {
        let result = '';
        reports.map(report => {
            if (report.id === id) {
                report.reportFiles.map(file => {
                    result += '<div><a target="_blank" href="' + process.env.GATSBY_API_URL + 'files/' + file.name + '">' + file.name + '</a></div><hr/>';
                })
            }
        })
        return result;
    }

    const handleOnMapLoad = (ymaps) => {
        setYmapsObj(ymaps);
        setMapLoaded(true);
    }

    const handleMapRef = (ref) => {
        setMyMap(ref);
    }

    return (
        <Space direction={"vertical"} size={24} css={{width: "100%"}}>
            <Row justify={"center"}>
                <Link to={"/add"}>
                    <BigButton type="primary" icon={<PlusCircleFilled/>}>
                        Добавить
                    </BigButton>
                </Link>
            </Row>
            <Row justify={"center"}>
                {!mapLoaded && (
                    <LoadingOutlined style={{fontSize: 24}} spin/>
                )}
                <YMaps query={{apikey: process.env.GATSBY_YMAP_API_KEY}}>
                    <Map
                        instanceRef={handleMapRef}
                        width={"100%"}
                        height={"600px"}
                        onLoad={handleOnMapLoad}
                        state={mapState}
                        modules={["util.bounds", "GeoObjectCollection"]}
                    >
                        {reports.map(item => (
                            <Placemark
                                key={item.id}
                                geometry={item.coordinates.split(";")}
                                defaultProperties={getPropertiesByStatus(item.id, item.status, item.title)}
                                defaultOptions={{iconColor: getPlacemarkColorByStatus(item.status)}}
                                modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                            />
                        ))}
                        <SearchControl options={{noPlacemark: true}}/>
                    </Map>
                </YMaps>
            </Row>
        </Space>
    )
}

export default HomePage